
export interface IFormLocal {
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  pontoReferencia: string;
  unidade: string;
}

export enum SIM_NAO {
  SIM = 'SIM',
  NAO = 'NAO',
}

export enum SIM_NAO_SEI {
  SIM = 'SIM',
  NAO = 'NAO',
  NAO_SEI = 'NAO_SEI'
}

export enum SOLICITACAO_REFERENTE {
  MAU_CHEIRO = 'MAU_CHEIRO',
  REPOSICAO_TAMPA = 'REPOSICAO_TAMPA'
}

export enum LOCAL_RECOLOCAR {
  RUA = 'RUA',
  CALCADA = 'CALCADA'
}

export enum LOCAL_VAZAMENTO {
  RUA = 'RUA',
  CALCADA = 'CALCADA',
  CAVALETE = 'CAVALETE'
}

export enum PAVIMENTO_VAZAMENTO {
  TERRA = 'TERRA',
  ASFALTO = 'ASFALTO'
}

export enum IRREGULARIDADE {
  VIOLACAO_CAVALETE = 'VIOLACAO_CAVALETE',
  VIOLACAO_RAMAL_PREDIAL = 'VIOLACAO_RAMAL_PREDIAL',
  BY_PASS = 'BY_PASS',
  IRREGULARIDADE_HIDROMETRO = 'IRREGULARIDADE_HIDROMETRO',
  LIGACAO_CLANDESTINA = 'LIGACAO_CLANDESTINA',
  HIDROMETRO_INVERTIDO = 'HIDROMETRO_INVERTIDO',
  LIGACAO_SEM_LACRE = 'LIGACAO_SEM_LACRE',
  LACRE_HIDROMETRO_VIOLADO = 'LACRE_HIDROMETRO_VIOLADO',
  HIDROMETRO_RETIRADO = 'HIDROMETRO_RETIRADO',
  HIDROMETRO_DANIFICADO = 'HIDROMETRO_DANIFICADO',
  ESGOTO_CONECTADO_SEM_CONTRATO = 'ESGOTO_CONECTADO_SEM_CONTRATO',
  LANCAMENTO_INDEVIDO_NA_REDE_DE_ESGOTO = 'LANCAMENTO_INDEVIDO_NA_REDE_DE_ESGOTO',
  FORNECIMENTO_DE_AGUA_A_TERCEIROS = 'FORNECIMENTO_DE_AGUA_A_TERCEIROS',
  OUTROS = 'OUTROS'
}
