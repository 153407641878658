import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { JwtService, USER_TYPE } from '@service/jwt.service';
import { LoaderService } from '@service/loader.service';
import { GtmService } from '@service/gtm.service';

import { NavigatorService } from '@service/navigator.service';
import { LocalStorageService } from '@service/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AutoAtendimentoAccessGuard implements CanActivate {
  constructor(
    private loaderService: LoaderService,
    private jwtService: JwtService,
    private navigatorService: NavigatorService,
    private dataLayer: GtmService,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    var allowed: boolean = this.jwtService.getUserType() == USER_TYPE.AUTOATENDIMENTO;

    if (!allowed) {
      // TODO: Quando houver histórico de navegação, utilizar aqui
      console.error(`Tentativa de acessar ${window.location} usando ${USER_TYPE[this.jwtService.getUserType()]}`);

      this.dataLayer.set('Acesso permitido apenas como cliente', {
        route: this.navigatorService.cleanURL(state.url)
      });

      const lastRoute = this.localStorageService.getItem('lastRoute');

      if (!!lastRoute) {
        this.localStorageService.setItem('lastRoute', 'home');
      } else {
        this.router.navigate(['/acesso-permitido-apenas-como-cliente']);
        this.loaderService.setPageLoader(false);
      }
    }

    return allowed;
  }
}
