import { Injectable } from '@angular/core';

import { HttpService } from '@service/http.service';
import { apiConfig } from '@config/api.config';
import { AgrupamentoViewModel } from '@model/agrupamento.resolver';
import { AgrupamentoResponseMaker } from '@model/agrupamento.resolver';
import { AgrupamentoRequest, AgrupamentoSegundaViaRequest } from '@model/agrupamento.resolver';

@Injectable({
  providedIn: 'root'
})
export class AgrupamentoService {
  constructor(private httpService: HttpService) {}

  gerarGuia(payload: AgrupamentoRequest): Promise<AgrupamentoViewModel> {
    return this.httpService
      .sendPostRequest(apiConfig.conta.geraGuiaPagamento, payload)
      .toPromise()
      .then(({ body }) => AgrupamentoResponseMaker.create(body));
  }

  segundoViaGuiaPagamento(payload: AgrupamentoSegundaViaRequest) {
    return this.httpService.sendPostRequestArrayBuffer(apiConfig.conta.segundaVia.guiaPagamento, payload).toPromise();
  }
}
