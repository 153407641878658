export interface ParcelamentoAgenteLimiteRequestDTO {
  valorTotalDivida: number;
  temParcelamento: boolean;
  matricula: number;
}

export class ParcelamentoAgenteLimiteRequestMaker {
  static create(payload: ParcelamentoAgenteLimiteRequestDTO): ParcelamentoAgenteLimiteRequestDTO {
    return {
      valorTotalDivida: payload.valorTotalDivida,
      temParcelamento: payload.temParcelamento,
      matricula: payload.matricula
    };
  }
}

export interface ParcelamentoAgenteSimularRequestDTO {
  contasNaoParcelar: any[];
  quantidadeParcelas: number;
  valorEntrada: number;
  matricula: number;
  desprezaCorrecao: boolean;
  desprezaJuros: boolean;
  desprezaMulta: boolean;
  desprezaJurosFinanciamento: boolean;
}

export class ParcelamentoAgenteSimularRequestMaker {
  static create(payload: ParcelamentoAgenteSimularRequestDTO): ParcelamentoAgenteSimularRequestDTO {
    return {
      contasNaoParcelar: payload.contasNaoParcelar,
      quantidadeParcelas: payload.quantidadeParcelas,
      valorEntrada: payload.valorEntrada,
      matricula: payload.matricula,
      desprezaCorrecao: payload.desprezaCorrecao,
      desprezaJuros: payload.desprezaJuros,
      desprezaMulta: payload.desprezaMulta,
      desprezaJurosFinanciamento: payload.desprezaJurosFinanciamento
    };
  }
}

export interface ParcelamentoEfetivarRequestDTO {
  contasNaoParcelar: any[];
  idSimulacao: number;
  matricula: number;
}

export interface ParcelamentoAgenteEfetivarInput {
  contasNaoParcelar: string;
  idSimulacao: number;
  matricula: string;
}

export class ParcelamentoEfetivarRequestMaker {
  static create(payload: ParcelamentoEfetivarRequestDTO): ParcelamentoEfetivarRequestDTO {
    return {
      contasNaoParcelar: payload.contasNaoParcelar,
      idSimulacao: payload.idSimulacao,
      matricula: payload.matricula
    };
  }
}

export interface ParcelamentoLimiteRequestDTO {
  valorTotalDivida: number;
  temParcelamento: boolean;
}

export class ParcelamentoLimiteRequestMaker {
  static create(payload: ParcelamentoLimiteRequestDTO): ParcelamentoLimiteRequestDTO {
    return {
      valorTotalDivida: payload.valorTotalDivida,
      temParcelamento: payload.temParcelamento
    };
  }
}

export interface ParcelamentoSimularRequestDTO {
  contasNaoParcelar: any[];
  quantidadeParcelas: number;
  valorEntrada: number;
  matricula: number;
}

export class ParcelamentoSimularRequestMaker {
  static create(payload: ParcelamentoSimularRequestDTO): ParcelamentoSimularRequestDTO {
    return {
      contasNaoParcelar: payload.contasNaoParcelar,
      quantidadeParcelas: payload.quantidadeParcelas,
      valorEntrada: payload.valorEntrada,
      matricula: payload.matricula
    };
  }
}

export interface ParcelamentoTermoRequestDTO {
  anoTermo: number;
  numTermo: number;
  matricula: number;
}

export interface ParcelamentoTermoEmailRequestDTO {
  email: string;
  anoTermo: number;
  numTermo: number;
  matricula: number;
}

export class ParcelamentoTermoRequestMaker {
  static create(payload: ParcelamentoTermoRequestDTO): ParcelamentoTermoRequestDTO {
    return {
      anoTermo: payload.anoTermo,
      numTermo: payload.numTermo,
      matricula: payload.matricula
    };
  }
}

export class ParcelamentoAgenteLimitesResponse {
  valorMinimoEntrada?: number;
  valorMinimoParcela?: number;
  quantidadeMaximaParcelas?: number;
  valorMinLiberadoParcelamento?: number;
  qdteMinLiberadaParcelamento?: number;
  dataVencimentoEntrada?: string;
  referenciaPrimeiraParcela?: string;
  bloqueadoParcelamento?: boolean;
  mensagemSeBloqueadoParcelamento?: string;
  exibeParaAgenteCheckBoxesDespreza?: boolean;
}

export class ParcelamentoAgenteLimitesResponseMaker {
  static create(data: ParcelamentoAgenteLimitesResponse): ParcelamentoAgenteLimitesResponse {
    let instance = new ParcelamentoAgenteLimitesResponse();
    Object.assign(instance, data);
    return data ? instance : {};
  }
}

export interface ParcelamentoAgenteSimularResponse {
  valorPrimeiraParcela?: number;
  valorDemaisParcelas?: number;
  idSimulacao?: number;
  valorTotalParcela?: number;
  jurosFinanciamento?: number;
}

export class ParcelamentoAgenteSimularResponseMaker {
  static create(data: ParcelamentoAgenteSimularResponse): ParcelamentoSimularViewModel {
    return data ? { ...data } : {};
  }
}

export interface ParcelamentoContasResponse {
  contasParcelaveis?: ParcelamentoContasDetailResponse[];
  parcelamentoAtivo?: ParcelamentoContasDetailResponse[];
}

export interface ParcelamentoContasDetailResponse {
  mesLancto?: number;
  anoLancto?: number;
  vencimento?: string;
  valor?: number;
  tributo?: string;
  numAviso?: number;
  situacaoPagto?: string;
  numEmissao?: number;
}

export class ParcelamentoContasResponseMaker {
  static create(data: ParcelamentoContasResponse): ParcelamentoContasViewModel {
    return data ? { ...data } : {};
  }
}

export interface ParcelamentoEfetivarResponse {
  anoTermo?: number;
  numTermo?: number;
  mesLancto?: number;
  parcelaEntrada?: {
    tributo: string;
    anoLancto: number;
    numAviso: number;
    numEmissao: number;
  };
}

export class ParcelamentoEfetivarResponseMaker {
  static create(data: ParcelamentoEfetivarResponse): ParcelamentoEfetivarViewModel {
    return data ? { ...data } : {};
  }
}

export class ParcelamentoLimitesResponse {
  valorMinimoEntrada?: number;
  valorMinimoParcela?: number;
  quantidadeMaximaParcelas?: number;
  valorMinLiberadoParcelamento?: number;
  qdteMinLiberadaParcelamento?: number;
  dataVencimentoEntrada?: string;
  referenciaPrimeiraParcela?: string;
  bloqueadoParcelamento?: boolean;
  mensagemSeBloqueadoParcelamento?: string;
}

export class ParcelamentoLimitesResponseMaker {
  static create(data: ParcelamentoLimitesResponse): ParcelamentoLimitesResponse {
    let instance = new ParcelamentoLimitesResponse();
    Object.assign(instance, data);
    return data ? instance : {};
  }
}

export interface ParcelamentoSimularResponse {
  valorPrimeiraParcela?: number;
  valorDemaisParcelas?: number;
  idSimulacao?: number;
  valorTotalParcela?: number;
  jurosFinanciamento?: number;
}

export class ParcelamentoSimularResponseMaker {
  static create(data: ParcelamentoSimularResponse): ParcelamentoSimularViewModel {
    return data ? { ...data } : {};
  }
}

export interface ParcelamentoTermoResponse {
  anoTermo?: number;
  numTermo?: number;
}

export class ParcelamentoTermoResponseMaker {
  static create(data: ParcelamentoTermoResponse): ParcelamentoTermoViewModel {
    return data ? { ...data } : {};
  }
}

export interface ParcelamentoContasViewModel {
  contasParcelaveis?: ParcelamentoContasDetailViewModel[];
  parcelamentoAtivo?: ParcelamentoContasDetailViewModel[];
}

export interface ParcelamentoContasDetailViewModel {
  mesLancto?: number;
  anoLancto?: number;
  vencimento?: string;
  valor?: number;
  valorAtualizado?: number;
  tributo?: string;
  numAviso?: number;
  situacaoPagto?: string;
  numEmissao?: number;
  selected?: boolean;
}

export interface ParcelamentoEfetivarViewModel {
  anoTermo?: number;
  numTermo?: number;
  mesLancto?: number;
  parcelaEntrada?: {
    tributo?: string;
    anoLancto?: number;
    numAviso?: number;
    numEmissao?: number;
  };
  contasNaoParcelar?: any[];
  idSimulacao?: number;
}

export interface ParcelamentoSimularViewModel {
  valorPrimeiraParcela?: number;
  valorDemaisParcelas?: number;
  idSimulacao?: number;
  valorTotalParcela?: number;
  jurosFinanciamento?: number;
}

export interface ParcelamentoTermoViewModel {
  anoTermo?: number;
  numTermo?: number;
}
