export interface AgrupamentoFaturasRequest {
  anoLancto: number;
  numAviso: number;
  numEmissao: number;
  tributo: string;
}

export interface AgrupamentoRequest {
  faturas: AgrupamentoFaturasRequest[];
  matricula: string;
  zona: number;
}

export interface AgrupamentoSegundaViaRequest {
  anoExtrato: number;
  matricula: string;
  numExtrato: number;
  zona: number;
}

export interface AgrupamentoResponse {
  anoExtrato?: number;
  codBarras?: string;
  dataVencimento?: string;
  linhaDigitavel?: string;
  numExtrato?: number;
  tributo?: string;
  valorTotal?: number;
}

export interface AgrupamentoViewModel {
  anoExtrato?: number;
  codBarras?: string;
  dataVencimento?: string;
  linhaDigitavel?: string;
  numExtrato?: number;
  tributo?: string;
  valorTotal?: number;
}

export class AgrupamentoResponseMaker {
  static create(data: AgrupamentoResponse) {
    return { ...data };
  }
}

export class ParcelamentoLimitesResponseMaker {
  static create(data: AgrupamentoResponse): AgrupamentoViewModel {
    return data ? { ...data } : {};
  }
}
