import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { HttpService } from '@service/http.service';
import { apiConfig } from '@config/api.config';
import { ConsultaDebitosRequest, DebitListRequest } from '@model/debito.resolver';
import { DebitResponse } from '@model/debito.resolver';
import { BillReadingRequest } from '@model/fatura.resolver';
import { BillReadingResponse } from '@model/fatura.resolver';
import { BillResponse } from '@model/fatura.resolver';
import { NegativeCertificateResponse } from '@model/certificado-negativo.resolver';
import { BillRequest } from '@model/fatura.resolver';

@Injectable({
  providedIn: 'root'
})
export class FaturaService {
  constructor(private httpService: HttpService) {}

  consultaDebitos(payload: ConsultaDebitosRequest, successCallback: Function, failureCallback: Function) {
    return this.httpService
      .sendGetRequest(apiConfig.conta.consultaDebitos(payload.unidade, payload.documento))
      .subscribe(
        (succeedResponse) => successCallback(succeedResponse.body),
        (failureResponse) => failureCallback(failureResponse.body)
      );
  }

  getUserDebits(payload: DebitListRequest, successCallbackAction): Array<DebitResponse> {
    return this.httpService
      .sendPostRequest(apiConfig.conta.listaDebitos, payload)
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse.body));
  }

  generateSecondInvoice(payload, successCallbackAction) {
    return this.httpService
      .sendPostRequestArrayBuffer(apiConfig.conta.segundaVia.url, payload)
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse));
  }

  getNegativeCertificateData(payload: BillRequest, successCallbackAction): any {
    return this.httpService
      .sendGetRequest(apiConfig.conta.certidaoNegativa.url(payload.matriculaImovel.toString()))
      .pipe(
        map((response) => {
          const responseBody = (<any>response).body;
          const negativeCertificateResponse = <NegativeCertificateResponse>responseBody;
          return negativeCertificateResponse;
        })
      )
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse));
  }

  getContasMensais(payload: BillRequest, successCallbackAction): Array<BillResponse> {
    return this.httpService
      .sendPostRequest(apiConfig.conta.urlMensais, payload)
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse.body));
  }

  getUserBills(payload: BillRequest, successCallbackAction): Array<BillResponse> {
    return this.httpService
      .sendPostRequest(apiConfig.conta.url, payload)
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse.body));
  }

  getAutomaticDebitInformation(successCallbackAction): any {
    const options = { responseType: 'text' as 'text' };
    return this.httpService
      .sendGetRequestWithOptions(apiConfig.conta.debitoAutomatico, options)
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse));
  }

  getUserBillReading(payload: BillReadingRequest, successCallbackAction): Array<BillReadingResponse> {
    return this.httpService
      .sendPostRequest(apiConfig.leitura, payload)
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse.body));
  }
}
