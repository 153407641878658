import { Injectable } from '@angular/core';
import { apiConfig } from '@config/api.config';
import { HttpService } from '@service/http.service';
import { LocalStorageService } from '@service/localstorage.service';

interface IConsultarSegundaViaRapidaRequest {
  unidade: string
  matricula: string
  documento: string
}

interface IFaturaSegundaViaRapidaRequest {
  unidade: string
  documento: string,
  matricula: number,
  mesLancto: number,
  anoLancto: number,
  zona: number,
  tributo: string
}

interface IFaturasSegundaViaRapida {
  anoLancto: number,
  numEmissao: number,
  numAviso: number,
  tributo: string
}

interface IGuiaSegundaViaRapidaRequest {
  unidade: string
  documento: string,
  matricula: number,
  zona: number,
  faturas: IFaturasSegundaViaRapida[]
}

@Injectable({
  providedIn: 'root'
})
export class SegundaViaRapidaService {
  constructor(private httpService: HttpService, private localStorageService: LocalStorageService) {}

  consultar(payload: IConsultarSegundaViaRapidaRequest, successCallback: any, failureCallback: any) {
    return this.httpService
      .sendGetRequest(
        apiConfig.conta.segundaViaRapida.consulta(payload.unidade, payload.matricula, payload.documento)
      )
      .subscribe(
        (succeedResponse: any) => successCallback(succeedResponse.body),
        (failureResponse: any) => failureCallback(failureResponse.body)
      );
  }

  downloadFatura(payload: IFaturaSegundaViaRapidaRequest, successCallback: any, failureCallback: any) {
    return this.httpService.sendPostRequestArrayBuffer(apiConfig.conta.segundaViaRapida.downloadFatura, payload).subscribe(
      (succeedResponse: any) => successCallback(succeedResponse),
      (failureResponse: any) => failureCallback(failureResponse.body)
    );
  }

  downloadGuia(payload: IGuiaSegundaViaRapidaRequest, successCallback: any, failureCallback: any) {
    return this.httpService.sendPostRequestArrayBuffer(apiConfig.conta.segundaViaRapida.downloadGuia, payload).subscribe(
      (succeedResponse: any) => successCallback(succeedResponse),
      (failureResponse: any) => failureCallback(failureResponse.body)
    );
  }
}
