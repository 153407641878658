import { Injectable } from '@angular/core';
import { apiConfig } from '@config/api.config';
import { HttpService } from '@service/http.service';

export enum ORDEM_SERVICO {
  EM_ANALISE = 'SERVIÇO EM ANÁLISE',
  EM_SERVICO = 'SERVIÇO EM ANDAMENTO',
  CANCELADO = 'SERVIÇO NÃO EXECUTADO',
  CONCLUIDO = 'SERVIÇO CONCLUÍDO'
}

export interface ITableConsultaOrdemServico {
  activity: string;
  date: string;
  previsao: string;
  protocol: string;
  status: ORDEM_SERVICO;
  type: string;
}

interface IConsultarOrdensRequest {
  unidade: string;
  documento: string;
  numPedido: string;
  ano: string;
}

interface IContratoOrdemRequest {
  numPedido: string;
  ano: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrdemServicoService {
  constructor(private httpService: HttpService) {}

  consultaOrdens(payload: IConsultarOrdensRequest, successCallback: any, failureCallback: any) {
    return this.httpService
      .sendGetRequest(
        apiConfig.solicitacao.consultaOrdem(payload.unidade, payload.documento, payload.numPedido, payload.ano)
      )
      .subscribe(
        (succeedResponse: any) => successCallback(succeedResponse.body),
        (failureResponse: any) => failureCallback(failureResponse.body)
      );
  }

  listarOrdens(matricula: string, successCallback: any, failureCallback?: any) {
    return this.httpService.sendGetRequest(apiConfig.solicitacao.listaOrdem(matricula)).subscribe(
      (succeedResponse: any) => successCallback(succeedResponse.body),
      (failureResponse: any) => failureCallback(failureResponse.body)
    );
  }

  downloadOrdem(successCallback: any, failureCallback: any) {
    return this.httpService.sendGetRequestArrayBuffer(apiConfig.imovel.contrato).subscribe(
      (succeedResponse: any) => successCallback(succeedResponse),
      (failureResponse: any) => failureCallback(failureResponse.body)
    );
  }
}
